<template>
    <section class="profileSidebar px-3 px-lg-4 py-4">
        <nav style="--bs-breadcrumb-divider: '\203A'" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#" class="text-decoration-none">My Account</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    {{ routeName }}
                </li>
            </ol>
        </nav>

        <div class="welcome-msg my-4 my-sm-5">
            <h3 class="fw-normal">Welcome,</h3>
            <h5>{{ isLoggedIn && user ? user.name : 'Guest' }}</h5>
        </div>

        <ul class="list-unstyled profileSidebar__nav">
            <li
                @click="$router.push({ name: 'PointHistory' })"
                class="profileSidebar__item"
                :class="[routeName == 'PointHistory' ? 'active' : '']"
                v-if="isLoggedIn"
            >
                Point History
            </li>
            <li
                @click="$router.push({ name: 'MyOrder' })"
                class="profileSidebar__item"
                :class="[routeName == 'MyOrder' ? 'active' : '']"
                v-if="isLoggedIn"
            >
                My Order
            </li>
            <li
                @click="$router.push({ name: 'CartList' })"
                class="profileSidebar__item"
                :class="[routeName == 'CartList' ? 'active' : '']"
            >
                My Cart ({{ carts.length }})
            </li>
            <li
                @click="$router.push({ name: 'WishList' })"
                class="profileSidebar__item"
                :class="[routeName == 'WishList' ? 'active' : '']"
                v-if="isLoggedIn"
            >
                My Wishlist ({{ wishlistCount }})
            </li>
            <li
                @click="$router.push({ name: 'ProfileEdit' })"
                class="profileSidebar__item"
                :class="[routeName == 'ProfileEdit' ? 'active' : '']"
                v-if="isLoggedIn"
            >
                Edit Profile
            </li>
            <li
                @click="$router.push({ name: 'PasswordEdit' })"
                class="profileSidebar__item"
                :class="[routeName == 'PasswordEdit' ? 'active' : '']"
                v-if="isLoggedIn"
            >
                Change Password
            </li>
            <li
                class="profileSidebar__item"
                v-if="isLoggedIn"
                @click="logoutModal = true"
            >
                Logout
            </li>
        </ul>

        <div
            v-if="logoutModal"
            class="modal logoutModal"
            tabindex="-1"
            aria-labelledby="logoutModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header border-0 pb-0">
                        <h4 class="mt-2">
                            <font-awesome-icon
                                :icon="['fas', 'exclamation-circle']"
                                class="logout-icon"
                            />
                            Logout
                        </h4>
                        <a
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            href="javascript:void(0)"
                            @click="logoutModal = false"
                        ></a>
                    </div>
                    <div class="modal-body">
                        <p class="fw-bold ms-1">
                            {{ $t('profile.logout.sureStatus') }}
                        </p>
                        <div class="text-end">
                            <a
                                href="javascript:void(0)"
                                class="me-2"
                                @click="logout"
                            >
                                Yes
                            </a>
                            <a
                                href="javascript:void(0)"
                                data-bs-dismiss="modal"
                                @click="logoutModal = false"
                            >
                                No
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { onLogout } from '../../graphql/vue-apollo';
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            logoutModal: false,
        };
    },

    computed: {
        routeName() {
            return this.$route.name;
        },

        ...mapGetters({
            wishlistCount: 'wishlist/getWishlistCount',
            carts: 'cart/getCarts',
            isLoggedIn: 'auth/isLoggedIn',
            user: 'auth/getUser',
        }),
    },

    methods: {
        logout() {
            this.logoutModal = false;
            onLogout(this.$apollo.provider.defaultClient);
            this.$store.commit('auth/REMOVE_AUTH');
            this.$store.commit('cart/RESET_CART');
            this.$router.push({ name: 'Home' });
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/components/profileSidebar.scss';

.logout-icon {
    color: #f59a33;
}

.logoutModal {
    a {
        text-decoration: none;
        color: #f59a33;
        text-transform: uppercase;
        font-weight: bold;
    }
}
</style>
