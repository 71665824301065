<template>
    <div class="cartItem border-bottom py-4 py-sm-5">
        <div class="cartItem__img">
            <img :src="cart.photo" :alt="cart.title" class="img-fluid" />
        </div>

        <div class="cartItem__info">
            <p>{{ cart.title }}</p>
            <h4 class="product-price fw-bold" v-if="cart.discounted_type == 0">
                {{ addCommaForPrice(cart.final_price) }} MMK
            </h4>
            <template v-else>
                <h4 class="product-price fw-bold" style="color: red;">
                    {{ addCommaForPrice(cart.discounted_price) }} MMK
                </h4>
                <div class="d-flex">
                    <p style="text-decoration: line-through;">
                        {{ addCommaForPrice(cart.final_price) }} MMK
                    </p>
                </div>
            </template>
            <div v-if="variant">
                <div
                    v-for="(opt, index) in variant.productvariantoptions"
                    class="flex-fill product-size"
                    :key="index"
                >
                    {{ opt.varianttype.name }}: {{ opt.variant_type_value }}
                </div>
            </div>

            <div class="d-flex align-items-stretch mt-3">
                <div class="cartCounter">
                    <button
                        type="button"
                        class="counter-btn"
                        @click="descCount"
                    >
                        -
                    </button>
                    <input
                        type="text"
                        readonly="readonly"
                        class="counter-input border-0"
                        :value="cartCount"
                    />
                    <button type="button" class="counter-btn" @click="incCount">
                        +
                    </button>
                </div>

                <button
                    class="btn btn--square btn--gray cartItem__removeBtn"
                    @click="removeFromCart"
                >
                    <font-awesome-icon :icon="['far', 'trash-alt']" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import CommaPrice from '@/mixins/CommaPrice';

// apollo query
import Variant from '@/graphql/queries/product/Variant.gql';

export default {
    mixins: [CommaPrice],

    props: {
        cart: {
            type: Object,
            required: true,
        },

        index: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            cartCount: 1,
            variant: null,
        };
    },

    mounted() {
        this.$apollo
            .query({
                query: Variant,

                variables: {
                    id: this.cart.product_variant_id,
                },
            })
            .then(response => {
                this.variant = response.data.productvariant;
                this.cartCount = this.cart.quantity;
            })
            .catch(error => {
                console.log(error);
            });
    },

    methods: {
        incCount() {
            if (this.cartCount < this.variant.total_in_stock) {
                this.cartCount++;
            } else {
                this.cartCount;
            }
            const payload = {
                id: this.cart.id,
                slug: this.cart.slug,
                title: this.cart.title,
                category_id: this.cart.category_id,
                quantity: this.cartCount,
                photo: this.cart.photo,
                product_variant_id: this.cart.product_variant_id,
                product_variant_name: this.cart.product_variant_name,
                final_price: this.cart.final_price,
                discounted_type: this.cart.discounted_type,
                discounted_price: this.cart.discounted_price,
                final_price_in_different_currency: this.cart
                    .final_price_in_different_currency,
            };

            const index = this.index;
            this.$store.commit('cart/UPDATE_CART', { index, payload });
            this.$emit('update-cart');
        },

        descCount() {
            if (this.cartCount > 1) {
                this.cartCount--;
            } else {
                this.cartCount = 1;
            }
            const payload = {
                id: this.cart.id,
                slug: this.cart.slug,
                title: this.cart.title,
                category_id: this.cart.category_id,
                quantity: this.cartCount,
                photo: this.cart.photo,
                product_variant_id: this.cart.product_variant_id,
                product_variant_name: this.cart.product_variant_name,
                final_price: this.cart.final_price,
                discounted_type: this.cart.discounted_type,
                discounted_price: this.cart.discounted_price,
                final_price_in_different_currency: this.cart
                    .final_price_in_different_currency,
            };

            const index = this.index;
            this.$store.commit('cart/UPDATE_CART', { index, payload });
            this.$emit('update-cart');
        },

        removeFromCart() {
            this.$store.commit('cart/REMOVE_CART', this.index);
            this.$emit('update-cart');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/sass/components/cartItem.scss';
@import '@/sass/components/cartCounter.scss';
</style>
