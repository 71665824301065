<template>
    <cart-list />
</template>

<script>
import CartList from '../../components/profile/CartList';

export default {
    components: {
        'cart-list': CartList,
    },
};
</script>
