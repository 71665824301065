<template>
    <section class="profileLayout">
        <profile-side-bar />

        <div class="container-fluid my-5 px-4 px-xl-5">
            <transition name="fade">
                <div
                    v-if="orderedMsgModal"
                    class="alert alert-dismissible fade show ordered-modal pe-4"
                    :class="ordered.type"
                    role="alert"
                >
                    <strong>{{ ordered.message }}</strong>
                    <a
                        type="button"
                        class="close close-alert-order"
                        data-dismiss="alert"
                        aria-label="Close"
                        @click="orderedMsgModal = false"
                    >
                        <span aria-hidden="true">&times;</span>
                    </a>
                </div>
            </transition>
            <div class="row">
                <div class="col-md-6 mb-5">
                    <h1 class="title--secondary">My Cart</h1>
                    <h2
                        class="title--tertiary"
                        v-if="!carts || carts.length == 0"
                    >
                        There is no items in your cart.
                    </h2>

                    <template v-if="carts && carts.length">
                        <cart-item
                            v-for="(cart, index) in carts"
                            :key="index"
                            :cart="cart"
                            :index="index"
                            @update-cart="updateCart"
                        />
                    </template>
                    <template v-else>
                        <router-link
                            :to="{
                                name: 'ProductList',
                            }"
                            class="btn btn--lg mb-2"
                            style="font-size: 1.3rem; padding: 0.55em 1.75em;"
                        >
                            Continue Shopping
                        </router-link>
                    </template>
                </div>
                <!-- CART-ITEMS COL ENDS -->

                <div class="col-md-6 offset-xl-1 col-xl-5">
                    <div
                        class="orderSummary border border-1 p-3 p-sm-4"
                        v-if="carts && carts.length"
                    >
                        <h2 class="mb-4">Order Summary</h2>

                        <table class="table table-borderless mb-1">
                            <thead class="border-bottom">
                                <tr>
                                    <th class="py-3">Mode of Payment:</th>
                                    <td class="py-3">
                                        Online Banking/Mobile Payment
                                    </td>
                                </tr>
                            </thead>
                            <tbody class="border-bottom">
                                <tr class="">
                                    <th class="py-3">Subtotal:</th>
                                    <td class="py-3">
                                        {{ addCommaForPrice(subTotal) }} MMk
                                    </td>
                                </tr>
                                <tr class="">
                                    <th class="py-3">Discount:</th>
                                    <td class="py-3">{{ discount }} MMK</td>
                                </tr>
                                <tr>
                                    <th class="py-3">Tax:</th>
                                    <td class="py-3">{{ totalTaxes }} MMK</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th class="py-3">Grand Total:</th>
                                    <td class="py-3">
                                        {{
                                            addCommaForPrice(
                                                calculateTotalCost(),
                                            )
                                        }}
                                        MMK*
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <div
                            class="
                                border-top
                                text-center
                                pb-2
                                pt-4 pt-sm-5
                                pb-sm-4
                            "
                        >
                            <template v-if="isLoadForOrder">
                                <button
                                    class="btn btn-primary loading-btn"
                                    type="button"
                                    disabled
                                >
                                    <span
                                        class="spinner-grow spinner-grow-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Loading...
                                </button>
                            </template>

                            <template v-else>
                                <main-button
                                    v-if="carts && carts.length > 0"
                                    class="btn btn--lg"
                                    name="PROCEED TO CHECKOUT"
                                    @clicked="fillInfoBox"
                                />
                                <main-button
                                    v-else
                                    class="btn btn--lg"
                                    name="PROCEED TO CHECKOUT"
                                    disabled="disabled"
                                />
                            </template>
                            <p class="mt-4 px-lg-5">
                                * Shipping charge may included depending upon
                                your location. Please proceed further to know.
                            </p>
                        </div>

                        <div
                            v-if="isLoggedIn"
                            class="d-flex ms-2 justify-content-center"
                        >
                            You have
                            <p
                                class="text-primary me-1 redeem-text"
                                @click="
                                    redeemModal = true;
                                    referModal = false;
                                "
                            >
                                Redeem Code
                            </p>
                            OR
                            <p
                                class="text-primary ms-1 refer-text"
                                @click="
                                    referModal = true;
                                    redeemModal = false;
                                "
                            >
                                Refer Code
                            </p>
                        </div>
                        <div class="text-center">
                            <p class="text-success">{{ response_msg }}</p>
                        </div>
                        <div class="text-center">
                            <p class="text-danger">{{ code_error_msg }}</p>
                        </div>

                        <template v-if="redeemModal">
                            <p class="text-center">
                                If You have redeem code, apply below.
                            </p>
                            <div class="input-group mb-3 redeem">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Write Your Redeem Code..."
                                    v-model="redeem_code"
                                />
                                <button
                                    class="btn"
                                    type="button"
                                    @click="checkRedeemCode"
                                    :disabled="is_used_redeem_code"
                                >
                                    Submit
                                </button>
                            </div>
                        </template>
                        <template v-if="referModal">
                            <p class="text-center">
                                If You have refer code, apply below.
                            </p>
                            <div class="input-group mb-3 refer">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Write Your Referral Code..."
                                    v-model="refer_code"
                                />
                                <button
                                    class="btn"
                                    type="button"
                                    @click="checkReferralCode"
                                    :disabled="is_used_refer_code"
                                >
                                    Submit
                                </button>
                            </div>
                        </template>
                    </div>
                    <!-- <div class="bank-info border border-1 p-3 p-sm-4 mt-3">
                        <h2 class="mb-4">Bank Transfer</h2>
                        <h5>Bank Acc for Diamond & Gemstone</h5>
                        <div class="bank">
                            <p>Aya</p>
                            <p>Daw Sandi - 1000 4040 291</p>
                        </div>
                        <div class="bank">
                            <p>KBZ</p>
                            <p>Daw Sandi - 3491 0123 8001 85001</p>
                        </div>
                        <div class="bank">
                            <p>CB</p>
                            <p>Daw Sandi - 0148 1001 0000 3379</p>
                        </div>
                        <div class="bank">
                            <p>Yoma</p>
                            <p>Daw Sandi - 0001 1029 0001 341</p>
                        </div>
                        <div class="bank">
                            <p>KBZ Pay</p>
                            <p>Daw Sandy - 095018374</p>
                        </div>
                    </div> -->
                    <div class="bank-info border border-1 p-3 p-sm-4 mt-3">
                        <h2 class="mb-4">Bank Transfer</h2>
                        <!-- <h5>Bank Acc for GOLD& 18K</h5> -->
                        <div class="bank">
                            <p>AYA Bank</p>
                            <p>Daw Sandi - 2002 6696 364</p>
                        </div>
                        <div class="bank">
                            <p>KBZ</p>
                            <p>Daw Sandy - 3495 0123 8001 85001</p>
                        </div>
                        <div class="bank">
                            <p>CB</p>
                            <p>Daw Sandi - 0148 6005 0003 7265</p>
                        </div>
                        <div class="bank">
                            <p>Yoma</p>
                            <p>Daw Sandi - 0001 1029 0001 341</p>
                        </div>
                        <div class="bank">
                            <p>KBZ Pay</p>
                            <p>Daw Sandy - 095018374</p>
                        </div>
                    </div>
                </div>
                <!-- ORDER-SUMMARY COL ENDS -->
            </div>

            <transition name="fade">
                <div
                    v-if="fillBoxModal"
                    class="modal fillbox"
                    id="fillbox"
                    tabindex="-1"
                    aria-labelledby="fillboxLabel"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                        <div class="modal-content container">
                            <div class="modal-header border-0 mt-2">
                                <a
                                    href="javascript:void(0)"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    @click="fillBoxModal = false"
                                ></a>
                            </div>

                            <div class="modal-body">
                                <div
                                    class="
                                        d-flex
                                        flex-wrap
                                        align-items-center
                                        justify-content-between
                                        mb-3
                                    "
                                >
                                    <h2
                                        class="flex-fill me-3 mb-3"
                                        id="exampleModalLabel"
                                    >
                                        {{ $t('profile.myCart.detailHeader') }}
                                    </h2>

                                    <div
                                        class="flex-fill text-end"
                                        v-if="!isLoggedIn"
                                    >
                                        I'm registered.
                                        <router-link
                                            :to="{ name: 'Login' }"
                                            class="login"
                                        >
                                            Login
                                        </router-link>
                                    </div>
                                </div>
                                <div
                                    class="error invalid-feedback d-block"
                                    v-if="submitStatus == 'ERROR' && authError"
                                >
                                    {{ authError }}
                                </div>
                                <form>
                                    <div class="mb-3">
                                        <label
                                            for="name"
                                            class="col-form-label"
                                        >
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control border-0"
                                            id="name"
                                            v-model="order.name"
                                        />
                                        <div
                                            class="error invalid-feedback d-block"
                                            v-if="
                                                submitStatus == 'ERROR' &&
                                                    !$v.order.name.required
                                            "
                                        >
                                            Name is required.
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label
                                                    for="Phone Number"
                                                    class="col-form-label"
                                                >
                                                    Phone Number
                                                </label>
                                                <input
                                                    type="text"
                                                    class="
                                                        form-control
                                                        border-0
                                                    "
                                                    id="phone"
                                                    v-model="order.phone"
                                                />
                                                <div
                                                    class="error invalid-feedback d-block"
                                                    v-if="
                                                        submitStatus ==
                                                            'ERROR' &&
                                                            !$v.order.phone
                                                                .required
                                                    "
                                                >
                                                    Phone is required.
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label
                                                    for="Email"
                                                    class="col-form-label"
                                                >
                                                    Email
                                                </label>
                                                <input
                                                    type="text"
                                                    class="
                                                        form-control
                                                        border-0
                                                    "
                                                    id="email"
                                                    v-model="order.email"
                                                />
                                                <div
                                                    class="error invalid-feedback d-block"
                                                    v-if="
                                                        submitStatus ==
                                                            'ERROR' &&
                                                            !$v.order.email
                                                                .required
                                                    "
                                                >
                                                    Email is required.
                                                </div>
                                                <div
                                                    class="error invalid-feedback d-block"
                                                    v-if="
                                                        submitStatus ==
                                                            'ERROR' &&
                                                            !$v.order.email
                                                                .email
                                                    "
                                                >
                                                    email must be email.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="mb-3"
                                        v-if="!isLoggedIn && order.term"
                                    >
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label
                                                    for="Password"
                                                    class="col-form-label"
                                                >
                                                    Password
                                                </label>
                                                <input
                                                    type="password"
                                                    class="
                                                        form-control
                                                        border-0
                                                    "
                                                    id="password"
                                                    v-model="order.password"
                                                />
                                                <div
                                                    class="error invalid-feedback d-block"
                                                    v-if="
                                                        submitStatus ==
                                                            'ERROR' &&
                                                            !$v.order.password
                                                                .required
                                                    "
                                                >
                                                    Password is required.
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label
                                                    for="Confirm Password"
                                                    class="col-form-label"
                                                >
                                                    Confirm Password
                                                </label>
                                                <input
                                                    type="password"
                                                    class="
                                                        form-control
                                                        border-0
                                                    "
                                                    id="confirm-password"
                                                    v-model="
                                                        order.confirm_password
                                                    "
                                                />
                                                <div
                                                    class="error invalid-feedback d-block"
                                                    v-if="
                                                        submitStatus ==
                                                            'ERROR' &&
                                                            !$v.order
                                                                .confirm_password
                                                                .required
                                                    "
                                                >
                                                    Confirm Password is
                                                    required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label
                                            for="address-text"
                                            class="col-form-label"
                                        >
                                            Full Address (for delivery)
                                        </label>
                                        <textarea
                                            class="form-control border-0"
                                            id="address-text"
                                            v-model="order.address"
                                            rows="3"
                                        ></textarea>
                                        <div
                                            class="error invalid-feedback d-block"
                                            v-if="
                                                submitStatus == 'ERROR' &&
                                                    !$v.order.address.required
                                            "
                                        >
                                            Address is required.
                                        </div>
                                    </div>
                                    <div class="form-check" v-if="!isLoggedIn">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            name="flexRadioDefault"
                                            id="accept-terms"
                                            v-model="order.term"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="accept-terms"
                                        >
                                            {{
                                                $t('profile.myCart.acceptTerms')
                                            }}
                                        </label>
                                    </div>
                                </form>
                            </div>
                            <div
                                class="
                                    modal-footer
                                    border-0
                                    text-center
                                    d-block
                                    mt-3
                                    mb-2
                                "
                            >
                                <button class="btn" @click="placeOrder">
                                    {{ $t('profile.myCart.placeOrder') }}
                                </button>
                                <p>
                                    {{ $t('profile.myCart.paymentType') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <transition name="fade">
                <div
                    v-if="confirmBoxModal"
                    class="modal confirmbox"
                    id="confirmbox"
                    tabindex="-1"
                    aria-labelledby="confirmboxLabel"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                        <div class="modal-content container">
                            <div class="modal-header border-0 mt-2">
                                <a
                                    href="javascript:void(0)"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    @click="confirmBoxModal = false"
                                ></a>
                            </div>
                            <div class="modal-body">
                                <div class="d-flex">
                                    <h2
                                        class="flex-fill"
                                        id="exampleModalLabel"
                                    >
                                        Please Confirm your details
                                    </h2>
                                    <div
                                        class="flex-fill pt-3 text-end"
                                        v-if="!isLoggedIn"
                                    >
                                        I'm registered.
                                        <router-link
                                            :to="{ name: 'Login' }"
                                            class="login"
                                        >
                                            Login
                                        </router-link>
                                    </div>
                                </div>
                                <form>
                                    <div class="mb-3">
                                        <label
                                            for="name"
                                            class="col-form-label"
                                        >
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control border-0"
                                            id="name"
                                            v-model="order.name"
                                            readonly
                                        />
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label
                                                    for="Phone Number"
                                                    class="col-form-label"
                                                >
                                                    Phone Number
                                                </label>
                                                <input
                                                    type="text"
                                                    class="
                                                        form-control
                                                        border-0
                                                    "
                                                    id="phone"
                                                    v-model="order.phone"
                                                    readonly
                                                />
                                            </div>
                                            <div class="col-md-6">
                                                <label
                                                    for="Email"
                                                    class="col-form-label"
                                                >
                                                    Email
                                                </label>
                                                <input
                                                    type="text"
                                                    class="
                                                        form-control
                                                        border-0
                                                    "
                                                    id="email"
                                                    v-model="order.email"
                                                    readonly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label
                                            for="address-text"
                                            class="col-form-label"
                                        >
                                            Full Address (for delivery)
                                        </label>
                                        <textarea
                                            class="form-control border-0"
                                            id="address-text"
                                            v-model="order.address"
                                            readonly
                                        ></textarea>
                                    </div>
                                    <!-- <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="accept-terms"
                                            v-model="order.term"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="accept-terms"
                                        >
                                            I accept to use my information for
                                            sign up.
                                        </label>
                                    </div> -->
                                </form>
                            </div>
                            <div
                                class="
                                    modal-footer
                                    border-0
                                    text-center
                                    d-block
                                    mt-3
                                    mb-2
                                "
                            >
                                <button class="btn" @click="makeOrder">
                                    Place Order
                                </button>
                                <p>Mode of Payment: Cash on Delivery</p>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </section>
</template>

<script>
import ProfileSideBar from '../reusable/ProfileSideBar';
//import ProfileAreaWrapper from '../reusable/ProfileAreaWrapper';
import Button from '../reusable/Button';
import CartItem from '../reusable/CartItem';
import { mapGetters } from 'vuex';
import CommaPrice from '@/mixins/CommaPrice';

// apollo query
import Taxes from '@/graphql/queries/order/Taxes.gql';
import { onLogin } from '../../graphql/vue-apollo';
import GetUser from '@/graphql/queries/auth/GetUser.gql';

// apollo mutation
import Checkout from '@/graphql/mutations/order/Checkout.gql';
import UserRegister from '@/graphql/mutations/order/UserRegisterCheckout.gql';
import CheckRedeem from '@/graphql/mutations/order/CheckRedeem.gql';
import CheckReferral from '@/graphql/mutations/order/CheckReferral.gql';

// vuelidate
import { validationMixin } from 'vuelidate';
import { required, email, sameAs, requiredIf } from 'vuelidate/lib/validators';

export default {
    mixins: [validationMixin, CommaPrice],

    data() {
        return {
            authError: '',
            fillBoxModal: false,
            confirmBoxModal: false,
            orderedMsgModal: false,
            redeemModal: false,
            referModal: false,
            submitStatus: null,
            product_variants: [],
            subTotal: 0,
            grandTotal: 0,
            totalTaxes: 0,
            discount: 0,
            is_used_refer_code: false,
            refer_code: '',
            redeem_code: '',
            code_error_msg: '',
            is_used_redeem_code: false,
            redeem_user_id: null,
            response_msg: '',
            has_categories: [],
            ordered: {
                type: '',
                message: '',
            },
            order: {
                name: '',
                phone: '',
                email: '',
                password: '',
                confirm_password: '',
                address: '',
                term: false,
            },
            isLoadForOrder: false,
        };
    },

    validations() {
        return {
            order: {
                name: {
                    required,
                },
                phone: {
                    required,
                },
                email: {
                    required: requiredIf(function() {
                        if (!this.isLoggedIn && this.order.term) {
                            return true;
                        } else if (this.isLoggedIn) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                    email,
                },
                password: {
                    required: requiredIf(function() {
                        return !this.isLoggedIn && this.order.term;
                    }),
                },
                confirm_password: {
                    required: requiredIf(function() {
                        return !this.isLoggedIn && this.order.term;
                    }),
                    sameAsPassword: sameAs('password'),
                },
                address: {
                    required,
                },
            },
        };
    },

    components: {
        'profile-side-bar': ProfileSideBar,
        // 'profile-area-wrapper': ProfileAreaWrapper,
        'main-button': Button,
        'cart-item': CartItem,
    },

    mounted() {
        this.getTaxes();
        this.addTotal();
    },

    // updated() {
    //     console.log('ewrwe');
    //     const isLoggedIn = this.$store.getters['auth/isLoggedIn'];
    //     const user = this.$store.getters['auth/getUser'];

    //     if (isLoggedIn && user) {
    //         this.order.name = user.name;
    //         this.order.phone = user.phone;
    //         this.order.email = user.email;
    //         this.order.address = user.shipping_address;
    //     }
    // },

    computed: {
        ...mapGetters({
            carts: 'cart/getCarts',
            taxes: 'order/getTaxes',
            isLoggedIn: 'auth/isLoggedIn',
            user: 'auth/getUser',
        }),
    },

    watch: {
        redeemModal() {
            if (!this.redeemModal) {
                this.discount = 0;
                this.is_used_redeem_code = false;
                this.redeem_user_id = null;
                this.redeem_code = '';
                this.code_error_msg = '';
                this.response_msg = '';
                this.calculateTotalCost();
            }
        },

        referModal() {
            if (!this.referModal) {
                this.is_used_refer_code = false;
                this.refer_code = '';
                this.response_msg = '';
                this.code_error_msg = '';
            }
        },
    },

    methods: {
        addTotal() {
            const carts = this.carts;
            carts.map(cart => {
                var price =
                    cart.discounted_type == 0
                        ? cart.final_price
                        : cart.discounted_price;
                this.subTotal = cart.quantity * price + this.subTotal;
            });
        },

        updateCart() {
            this.subTotal = 0;
            this.addTotal();
        },

        changeTerm() {
            this.order.term = !this.order.term;
        },

        fillInfoBox() {
            if (this.isLoggedIn && this.user) {
                this.order.name = this.user.name;
                this.order.phone = this.user.phone;
                this.order.email = this.user.email;
                this.order.address = this.user.shipping_address;
                this.order.term = true;
            }
            this.fillBoxModal = true;
        },

        placeOrder() {
            if (this.$v.$invalid) {
                console.log(this.$v);
                this.submitStatus = 'ERROR';
            } else {
                if (!this.isLoggedIn) {
                    if (this.order.term) {
                        this.$apollo
                            .mutate({
                                mutation: UserRegister,

                                variables: {
                                    name: this.order.name,
                                    email: this.order.email,
                                    phone: this.order.phone,
                                    password: this.order.password,
                                    password_confirmation: this.order
                                        .confirm_password,
                                    shipping_address: this.order.address,
                                },
                            })
                            .then(response => {
                                console.log(response.data);

                                this.$store.commit(
                                    'auth/ADD_TOKEN',
                                    response.data.userRegister.tokens
                                        .access_token,
                                );
                                this.$store.commit(
                                    'auth/ADD_REFRESH_TOKEN',
                                    response.data.userRegister.tokens
                                        .refresh_token,
                                );

                                onLogin(
                                    this.$apollo.provider.defaultClient,
                                    response.data.userRegister.tokens
                                        .access_token,
                                );

                                this.$apollo
                                    .getClient()
                                    .resetStore()
                                    .then(() => {
                                        this.$apollo
                                            .query({
                                                query: GetUser,
                                                fetchPolicy: 'no-cache',
                                            })
                                            .then(response => {
                                                console.log('here');
                                                this.$store.commit(
                                                    'auth/ADD_USER',
                                                    response.data,
                                                );
                                            })
                                            .catch(error => {
                                                console.log('there');
                                                console.log(error);
                                            });
                                    });

                                this.fillBoxModal = false;
                                this.confirmBoxModal = true;
                            })
                            .catch(errors => {
                                console.log(errors);
                                this.submitStatus = 'ERROR';
                                this.authError = errors.graphQLErrors[0]
                                    .extensions.validation['input.email'][0]
                                    ? 'The email is already been taken.'
                                    : errors.graphQLErrors[0].extensions.reason;
                            });
                    } else {
                        this.fillBoxModal = false;
                        this.confirmBoxModal = true;
                    }
                } else {
                    this.fillBoxModal = false;
                    this.confirmBoxModal = true;
                }
            }
        },

        formatProductVariant() {
            console.log(this.carts);
            if (this.carts && this.carts.length > 0) {
                this.carts.map(item => {
                    this.product_variants.push({
                        product_id: item.id,
                        product_name: item.title,
                        product_variant_id: item.product_variant_id,
                        product_variant_name: item.product_variant_name,
                        price: item.final_price,
                        quantity: item.quantity,
                    });
                });
            }
        },

        checkRedeemCode() {
            this.subTotal = 0;
            this.addTotal();
            this.$apollo
                .mutate({
                    mutation: CheckRedeem,

                    variables: {
                        user_id: this.user.id,
                        code: this.redeem_code,
                    },
                })
                .then(response => {
                    console.log(response);
                    const redeem = response.data.checkRedeemCode;
                    if (redeem.status == 'success') {
                        this.discount = redeem.promo_price;
                        this.is_used_redeem_code = true;
                        this.redeem_user_id = redeem.redeem_user_id;
                        this.code_error_msg = '';
                        this.response_msg = redeem.message;
                        console.log(redeem.promo_type, 'promotype');
                        if (redeem.promo_type == 'percentage') {
                            this.carts.map(cart => {
                                if (
                                    cart.category_id ==
                                        response.data.checkRedeemCode
                                            .category_id ||
                                    response.data.checkRedeemCode.category_id ==
                                        0
                                ) {
                                    let price =
                                        (cart.final_price / 100) *
                                        redeem.promo_percentage;
                                    console.log(price);
                                    this.subTotal =
                                        this.subTotal -
                                        Math.round(price) * cart.quantity;
                                    console.log(this.subTotal);
                                }
                            });
                        } else {
                            this.carts.map(cart => {
                                if (
                                    cart.category_id ==
                                        response.data.checkRedeemCode
                                            .category_id ||
                                    response.data.checkRedeemCode.category_id ==
                                        0
                                ) {
                                    this.subTotal =
                                        this.subTotal - this.discount;
                                }
                            });
                            // this.calculateTotalCost();
                        }
                    } else {
                        this.code_error_msg = redeem.message;
                        this.response_msg = '';
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        checkReferralCode() {
            this.$apollo
                .mutate({
                    mutation: CheckReferral,

                    variables: {
                        user_id: this.user.id,
                        code: this.refer_code,
                    },
                })
                .then(response => {
                    console.log(response);
                    const referral = response.data.checkReferralCode;
                    if (referral.status == 'success') {
                        this.is_used_refer_code = true;
                        this.code_error_msg = '';
                        this.response_msg = referral.message;
                        if (referral.promo_type == 'percentage') {
                            this.carts.map(cart => {
                                if (
                                    cart.category_id == referral.category_id ||
                                    referral.category_id == 0
                                ) {
                                    let price =
                                        (cart.final_price / 100) *
                                        referral.promo_percentage;
                                    console.log(price);
                                    this.subTotal =
                                        this.subTotal -
                                        Math.round(price) * cart.quantity;
                                    console.log(this.subTotal);
                                }
                            });
                        } else {
                            this.carts.map(cart => {
                                if (
                                    cart.category_id == referral.category_id ||
                                    referral.category_id == 0
                                ) {
                                    this.subTotal =
                                        this.subTotal - this.discount;
                                }
                            });
                            // this.calculateTotalCost();
                        }
                    } else {
                        this.code_error_msg = referral.message;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        calculateTotalCost() {
            return this.subTotal + this.totalTaxes - this.discount;
        },

        makeOrder() {
            this.confirmBoxModal = false;
            this.isLoadForOrder = true;
            // let val = this.$v;

            this.formatProductVariant();

            this.$apollo
                .mutate({
                    mutation: Checkout,

                    variables: {
                        data: {
                            is_login_user: this.isLoggedIn,
                            currency: 'kyats',
                            name: this.order.name,
                            email: this.order.email,
                            phone: this.order.phone,
                            address: this.order.address,
                            product_variants: this.product_variants,
                            taxes: this.taxes,
                            discount: this.discount,
                            is_used_refer_code: this.is_used_refer_code,
                            refer_code: this.refer_code,
                            redeem_code: this.redeem_code,
                            is_used_redeem_code: this.is_used_redeem_code,
                            redeem_user_id: this.redeem_user_id,
                        },
                    },
                })
                .then(response => {
                    console.log(response.data);
                    switch (response.data.checkout.code) {
                        case 1:
                        case 2:
                            this.ordered.type = 'alert-success';
                            this.ordered.message = 'Successfully Ordered!';
                            // this.orderedMsgModal = true;
                            this.redeem_code = '';
                            this.refer_code = '';
                            this.subTotal = 0;
                            this.discount = 0;
                            this.calculateTotalCost();

                            this.$store.commit('cart/RESET_CART');
                            // val.reset();

                            this.$store.commit('order/MAKE_ORDER', true);

                            this.isLoadForOrder = false;

                            this.$router.push({
                                name: 'MyOrder',
                            });

                            break;
                        case 3:
                        case 4:
                            this.ordered.type = 'alert-warning';
                            this.ordered.message =
                                'Please refresh your browser to get updated products!';
                            this.orderedMsgModal = true;
                            this.isLoadForOrder = false;

                            break;
                        case 5:
                            this.ordered.type = 'alert-error';
                            this.ordered.message =
                                response.response.data.checkout.message;
                            this.orderedMsgModal = true;
                            this.isLoadForOrder = false;

                            break;
                        case 6:
                            this.ordered.type = 'alert-error';
                            this.ordered.message =
                                response.response.data.checkout.message;
                            this.orderedMsgModal = true;
                            this.isLoadForOrder = false;

                            break;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getTaxes() {
            this.$apollo
                .query({
                    query: Taxes,
                })
                .then(response => {
                    console.log(response.data);
                    if (response.data.taxes.length > 0) {
                        let taxesArr = [
                            {
                                id: null,
                                name: null,
                                tax: null,
                            },
                        ];
                        response.data.taxes.map(t => {
                            taxesArr = [];
                            taxesArr.push({
                                id: t.id,
                                name: t.name,
                                tax: t.tax,
                            });
                        });
                        this.$store.commit('order/ADD_TAXES', taxesArr);
                        const taxes = response.data.taxes;
                        taxes.map(tax => {
                            this.totalTaxes = this.totalTaxes + tax.tax;
                        });
                    }
                })
                .catch(error => {
                    console.log(error.response);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/components/profileLayout.scss';
@import '@/sass/components/cartList.scss';
.close-alert-order {
    color: #0f5132;
    text-decoration: none;
    float: right;
    font-size: 20px;
    line-height: 23px;
}

.redeem-text,
.refer-text {
    cursor: pointer;
}

.redeem,
.refer {
    button {
        font-size: 15px;
    }
}
</style>
